<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4"
    },
    format: {
      type: String,
      required: false,
      default: "dd/mm/yy"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    inline :{
      type:Boolean, default : false
    },
    view: {
      type: String,
      required: false,
      default: undefined
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false
    },
    min : { type : Date, default : null},
    max : { type : Date, default : null}
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      }, set: function (val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
  }
}
</script>

<template>
  <div :class="this.className" class="form-group ak-calendar">

    <ak-label :required="isRequired">{{ this.label }}</ak-label>
    <Calendar :view="view" v-model="currentValue" :class="{'p-invalid':isInvalid && alreadySubmitted
    , 'p-disabled':isDisabled,'form-control' : !inline}" :date-format=this.format
              :required="this.required"
              :disabled="isDisabled"
              :inline="inline"
              :minDate="min"
              :maxDate="max"
              :showTime="showTime"
              :placeholder="getPlaceholder"></Calendar>
    <small
        v-if="isValidatorRequired"
        class="p-error">Ce champ est requis</small>

  </div>
</template>
