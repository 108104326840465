<script>

import AkLabel from "../general/AkLabel";

export default {
  components: {AkLabel},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type:[String ,Function],
      required: false,
      default: "label"
    },
    optionValue: {
      type: [String ,Function],
      required: false,
      default: "value"
    },
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      }, set: function (val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid && this.submitted;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.submitted;
    },
  }
}
</script>

<template>
  <div class="form-group mb-3" :class="this.className">
    <ak-label :required="isRequired">{{ this.label }}</ak-label>
    <Dropdown :disabled="this.disabled" class="form-control" v-model="currentValue"
              :option-label="this.optionLabel"
              :option-value="this.optionValue" :options=this.options
               :class="{'p-invalid':isInvalid}" :placeholder="getPlaceholder"/>
    <small
        v-if="isValidatorRequired"
        class="p-error">
        Le champ {{this.label}} est requis</small>
  </div>
</template>
