<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Ajouter" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
            </div>
          </template>
        </Toolbar>
        <DataTable :value="this.spectacles" :paginator="true" :rows="10" :loading="this.loading"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="{first} à {last} des {totalRecords} spectacles" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Liste des spectacles</h5>
            </div>
          </template>
          <Column field="name" header="Nom" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <router-link
                  :to="{name: 'spectacle', params: {spectacleId: slotProps.data.id}}"
              >{{ slotProps.data.name }}
              </router-link>
              <span class="p-column-title">Nom</span>
            </template>
          </Column>
           <Column field="name" header="Description" :sortable="true" headerStyle="width:50%; min-width:10rem;">
            <template #body="slotProps">
              {{slotProps.data.description}}
            </template>
          </Column>
          <Column field="spectacleId" header="Lien" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <a :href="goToRegisterPage(slotProps.data.spectacleId)" target="_blank">
                <Button icon="pi pi-link" class="p-button-rounded p-button-outlined p-button-primary mr-2" />
              </a>
            </template>
          </Column>
          <Column style="text-align: right">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editSpectacle(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteSpectacle(slotProps.data)" />
            </template>
          </Column>
        </DataTable>


        <Dialog v-model:visible="createSpectacleDialog" :style="{width: '450px'}" :header="isCreationLabel +' un spectacle'" :modal="true" class="p-fluid">
          <form v-on:submit.prevent="createSpectacle" ref="spectacleForm">
              <div class="flex flex-wrap gap-3" style="margin-top: 10px; margin-bottom: 20px">
                <div class="flex align-items-center">
                  <p>Type d'inscription au spectacle *</p>
                </div>
              </div>
              <div class="flex flex-wrap gap-3" style="margin-top: 10px; margin-bottom: 20px">
                <div class="flex align-items-center">
                  <RadioButton :required=true v-model="this.spectacle.type" inputId="type1" name="type" value="Inscription interne"  />
                  <label for="type1" class="ml-2">Inscription classique</label>
                </div>
                <div class="flex align-items-center">
                  <RadioButton :required=true v-model="this.spectacle.type" inputId="type2" name="type" value="Inscription externe" />
                  <label for="type2" class="ml-2">Inscription externe</label>
                </div>
              </div>
            <ak-dropdown required="true" v-model="this.spectacle.salleId" label="Salle" :options="this.rooms" option-label="name" option-value="salleId"></ak-dropdown>
              <ak-input-text :required=true v-model="this.spectacle.name" label="Nom" :validator="v$.spectacle.name" :submitted="this.submitted"></ak-input-text>
              <ak-input-text :required=true v-model="this.spectacle.description" label="Description" :validator="v$.spectacle.description" :submitted="this.submitted"></ak-input-text>
              <ak-input-text :required=true v-model="this.spectacle.webLink" label="Lien JMF du spectacle" :validator="v$.spectacle.webLink" :submitted="this.submitted"></ak-input-text>

              <template v-for="(date,i) of this.spectacle.spectacleDates" v-bind:key="date">
              <div class="grid">
              <ak-calendar className="mt-3 col-10" :required=true v-model="date.dateSpectacle" label="Date du spectacle"  :show-time=true :submitted="this.submitted"></ak-calendar>
              <Button v-if="spectacle.spectacleDates.length > 1" icon="pi pi-minus" class="p-button-rounded p-button-danger mr-2" @click="removeDate(i)" />
              </div>
            </template>
            <Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="addNewDate()" />

            <div v-if="this.spectacle.type === 'Inscription externe'" style="margin-top: 15px">
              <label >Modalités d'inscription *</label>
                <Textarea rows="5" cols="30"  v-model="this.spectacle.sessionInscription" label="Détails d'inscription à la session" :validator="v$.spectacle.sessionInscription" :submitted="this.submitted"></Textarea>
              </div>

              <div class="row">
                <ak-single-file className="mt-3" v-model="this.spectacle.picture" label="Image du spectacle" :multiple="false" :uploadFct="uploadPicture" :deleteFct="deletePicture"></ak-single-file>
                <template v-if="this.spectacle.picture">
                  <img
                      v-if="this.spectacle.picture"
                      alt="logo"
                      class="img-fluid logo-desktop round-avatar-xl"
                      style="max-width: 100%"
                      :src="'data:image/jpeg;base64,' +this.spectacle.picture.content" />
                </template>
              </div>
              <!--<ak-input-number :required=true v-model="this.spectacle.nbPlace" :validator="v$.spectacle.nbPlace" label="Nombre de places" :submitted="this.submitted" class-name="mb-3"></ak-input-number>
              <ak-input-text :required=true v-model="this.spectacle.place" label="Nom de la salle" :validator="v$.spectacle.place" :submitted="this.submitted"></ak-input-text>
              <ak-input-text :required=true v-model="this.spectacle.address" label="Adresse de la salle" :validator="v$.spectacle.address" :submitted="this.submitted"></ak-input-text>
              <ak-input-text :required=true v-model="this.spectacle.city" label="Ville de la salle" :validator="v$.spectacle.city" :submitted="this.submitted"></ak-input-text>
              <ak-input-number :required=true v-model="this.spectacle.zipCode" label="Code postal de la salle" :validator="v$.spectacle.zipCode" :submitted="this.submitted" class-name="mb-3"></ak-input-number>
              <ak-checkbox :required=true v-model="this.spectacle.handicapped" label="Accès handicapé ?" :validator="v$.spectacle.handicapped" :submitted="this.submitted"></ak-checkbox> -->
            <div class="p-dialog-footer">
              <!--<Button label="Annuler" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>-->
              <!-- Bouton pour valider le formulaire (il est différent des autres) -->
                <Button v-if="isCreation" @click.prevent="nextStep()" class="p-button-text">
                  <span class="pi pi-check p-button-icon p-button-icon-left"></span>
                  <span class="p-button-label">{{buttonLabel}}</span></Button>
              <Button v-if="!isCreation" @click.prevent="nextStep()" class="p-button-text">
                <span class="pi pi-check p-button-icon p-button-icon-left"></span>
                <span class="p-button-label">{{buttonLabel}}</span></Button>
            </div>
          </form>
        </Dialog>

        <Dialog v-model:visible="deleteSpectacleDialog" :style="{width: '450px'}" header="Confirmer" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="spectacle">Voulez-vous vraiment supprimer le spectacle <b>{{spectacle.name}}</b> ?</span>
          </div>
          <div class="p-dialog-footer">
              <Button label="Non" icon="pi pi-times" class="p-button-text" @click="deleteSpectacleDialog = false"/>
              <Button label="Oui" icon="pi pi-check" class="p-button-text" @click="deleteSpectacle" />
          </div>
        </Dialog>

      </div>
    </div>
  </div>

</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import AkInputText from "@/components/input/AkInputText";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import AkCalendar from "@components/input/AkCalendar";
import AkSingleFile from "@components/input/AkSingleFile";
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import SalleService from "../../service/SalleService";
import AkDropdown from "../../components/input/AkDropdown.vue";

export default {
  components: {AkInputText, AkCalendar, AkSingleFile,RadioButton, Textarea, AkDropdown},

  setup() {
    return {v$: useVuelidate()}
  },

  data() {
    return {
      spectacles: [],
      createSpectacleDialog: false,
      deleteSpectacleDialog : false,
      isCreation : false,
      spectacle: {
        picture: null,
        spectacleDates: [
          {spectacleId: null}
        ],
      },
      filters: {},
      submitted: false,
      loading: true,
      statuses: [
      ],
      step: 1,
      logoBase64: null,
      tempId: null,
      rooms: [],
    }
  },

  validations() {
    return {
      spectacle: {
        name: {required},
        description: {required},
        webLink: {required},
        type: {required},
        sessionInscription: {},
        spectacleDates: {
          $each: {
            dateSpectacle: {required},
          }
        },
      }
    }
  },

  spectacleService: null,
  salleService: null,
  created() {
    this.spectacleService = new SpectacleService();
    this.salleService = new SalleService();
  },

  mounted() {
    let spectaclesPromise = this.spectacleService.spectacles();
   let roomsPromise = this.salleService.salles();
  Promise.all([spectaclesPromise, roomsPromise]).then((values) => {
    this.spectacles = values[0];
    this.rooms = values[1];
    this.loading = false;
  });
  },

  computed: {
    buttonLabel() {
      if (this.step != 3) {
        return "Suivant";
      } else if(this.isCreation){
        return "Créer";
      }
      else {
        return "Modifier";
      }
    },
    isCreationLabel(){
      if(this.isCreation){
        return "Créer";
      }
      else {
        return "Modifier";
      }
    }
  },

  methods: {
    openNew() {
      this.step = 1;
      this.spectacle = {
        spectacleDates: [
          {spectacleId: null}]
      };
      this.submitted = false;
      this.createSpectacleDialog = true;
      this.isCreation = true;
      this.tempId = Math.random().toString().slice(-9);
    },
    hideDialog() {
      this.step = 1;
      this.createSpectacleDialog = false;
      this.submitted = false;
    },

    createSpectacle(){
      let _this = this;
      this.submitted = true;
      this.v$.$touch();
      console.log(this.v$)

      if (this.v$.$error) return;
      if(this.spectacle.picture == null){
        this.$toast.add({severity:'error', summary: 'Erreur', detail: 'Veuillez ajouter une image', life: 3000});
        return;
      }
      if(this.spectacle.spectacleId !== undefined){
        this.spectacleService.update(this.spectacle)
        this.spectacles[this.findIndexById(this.spectacle.spectacleId, this.spectacle.picture)] = this.spectacle;
        this.$toast.add({severity:'success', summary: 'Terminé', detail: this.spectacle.name + ' a bien été modifié', life: 3000});
      }
      else{
       this.spectacleService.create(this.spectacle, this.tempId).then(data => {
         _this.spectacle = data;
         this.spectacles.push(this.spectacle)
         this.$toast.add({severity:'success', summary: 'Terminé', detail: this.spectacle.name + ' a bien été créé', life: 3000});
       });
      }
      //this.createSpectacleDialog = false;
      this.submitted = false;
      return true;
    },

    editSpectacle(spectacle) {
      this.step = 1;
      this.spectacle = {...spectacle};
      this.createSpectacleDialog = true;
      this.isCreation = false;
    },

    confirmDeleteSpectacle(spectacle) {
      this.spectacle = spectacle;
      this.deleteSpectacleDialog = true;
    },
    deleteSpectacle() {
      this.spectacles = this.spectacles.filter(val => val.id !== this.spectacle.id);
      this.spectacleService.delete(this.spectacle);
      this.deleteSpectacleDialog = false;
      this.spectacle = {};
      this.$toast.add({severity:'success', summary: 'Terminé', detail: 'Spectacle supprimé', life: 3000});
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.spectacles.length; i++) {
        if (this.spectacles[i].spectacleId === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    goToRegisterPage(specId){
        return "/public/spectacle/"+specId+"/register"
      //route.push({name: "spectacleRegister", params: { spectacleId: specId }});
    },
    lastStep() {
      this.step--;
    },
    nextStep(){
          if(!this.createSpectacle()){
            return;
          }
        this.hideDialog();
    },
    uploadPicture(file) {
      if (this.spectacle.id != null) {
        this.spectacleService.uploadPicture(this.spectacle.spectacleId, file).then(data => {
          this.spectacle.picture = data;
        });
      }
   else {
        this.spectacleService.uploadPicture(this.tempId, file).then(data => {
          this.spectacle.picture = data;
        });
      }
    },
    deletePicture(file) {
      if (this.spectacle.id != null) {
        this.spectacleService.deletePicture(this.spectacle.spectacleId, file).then(
            this.spectacle.picture = null);
      }
      else {
        this.spectacle.picture = undefined;
      }
    },
    addNewDate(){
      this.spectacle.spectacleDates.push({spectacleId: this.spectacle.spectacleId});
    },
    removeDate(index){
      this.spectacle.spectacleDates.splice(index, 1);
    },
  }
}
</script>
